// Функция для закрытия меню
function closeMenu() {
  const headerBlock = document.querySelector(".header");
  headerBlock.classList.remove("active");
  document.body.classList.remove("active");
}

document.addEventListener("DOMContentLoaded", function () {
  const burgerClosetButton = document.querySelector(".burger-closet");
  const burgerOpenButton = document.querySelector(".burger-open");
  const headerBlock = document.querySelector(".header");

  // По клику на кнопку открываем меню
  burgerClosetButton.addEventListener("click", function () {
    headerBlock.classList.add("active");
    document.body.classList.add("active");
  });

  // По клику на кнопку закрываем меню
  burgerOpenButton.addEventListener("click", function () {
    headerBlock.classList.remove("active");
    document.body.classList.remove("active");
  });
});



document.addEventListener("DOMContentLoaded", function () {
  var withChildItems = document.querySelectorAll(".menu-link_children");
  var withChildItemsLink = document.querySelectorAll(".menu-link__open");
  var forEcosystemBlock = document.querySelector(".for-ecosystem");

  withChildItemsLink.forEach(function (link) {
    link.addEventListener("click", function (event) {
      event.preventDefault(); // Отменяем действие по умолчанию
      event.stopPropagation(); // Останавливаем всплытие события

      var parentItem = link.closest(".menu-link_children");
      var isAlreadyOpen = parentItem.classList.contains("show");

      // Убираем класс "show" у всех элементов с классом "with-child"
      withChildItems.forEach(function (item) {
        item.classList.remove("show");
      });

      // Убираем класс "show" у блока с классом "for-ecosystem"
      forEcosystemBlock.classList.remove("show");

      if (!isAlreadyOpen) {
        // Если элемент не был открыт, добавляем класс "show" к самому элементу и к блоку "for-ecosystem"
        parentItem.classList.add("show");
        forEcosystemBlock.classList.add("show");
      }
    });
  });

  document.addEventListener("click", function (event) {
    if (!event.target.closest(".for-ecosystem")) {
      withChildItems.forEach(function (item) {
        item.classList.remove("show");
      });
      forEcosystemBlock.classList.remove("show");
    }
  });
});


document.addEventListener("DOMContentLoaded", function () {
  const tipIcon = document.querySelector(".qu-this__icon");
  const tipBlock = document.querySelector(".qu-this__tip");

  // При клике на иконку, добавляем класс "active" блоку ".qu-this__tip"
  tipIcon.addEventListener("click", function (event) {
    event.stopPropagation(); // Предотвращаем всплытие события, чтобы не срабатывало событие на document
    tipBlock.classList.add("active");
  });

  // При клике на документ, удаляем класс "active" у блока ".qu-this__tip"
  document.addEventListener("click", function (event) {
    if (!tipBlock.contains(event.target)) {
      // Проверяем, что клик был вне блока
      tipBlock.classList.remove("active");
    }
  });
});

// Получаем все кнопки с атрибутом data-url
var buttons = document.querySelectorAll('[data-url]');

// Добавляем обработчик событий для каждой кнопки
buttons.forEach(function(button) {
    button.addEventListener('click', function() {
        var dataUrl = button.getAttribute('data-url');
        var targetElement = document.getElementById(dataUrl);

        if (targetElement) {
            // Используем smooth scroll для плавного перехода
            targetElement.scrollIntoView({ behavior: 'smooth' });

            // Если ширина экрана <= 1000, закрываем меню
            if (window.innerWidth <= 1000) {
              closeMenu();
            }
        }
    });
});